var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-8 settings-content px-4"},[_c('div',{staticClass:"row mx-0"},[_c('p',{staticClass:"settings-text color-gray mt-4 mb-4",staticStyle:{"max-width":"453px"}},[_vm._v(" Welcome to your email and contact preferences page. By default you are opted in to all emails when you register. You can opt out of certain emails by unchecking the box associated with the topic of the email. Remember to save your preferences to complete your selection. ")]),_c('div',{staticClass:"row mx-0 pt-3 w-100"},[_c('h4',{staticClass:"text-uppercase"},[_vm._v(" General Emails ")]),_vm._l((_vm.visibleOptions.filter(function (o) { return o.type === 'Subscribe'; })),function(option){return _c('div',{key:option.title,staticClass:"col-12 px-0"},[_c('b-form-checkbox',{attrs:{"value":option.topic,"disabled":!!option.disabled},on:{"input":_vm.selectOptions},model:{value:(_vm.topics),callback:function ($$v) {_vm.topics=$$v},expression:"topics"}},[_c('span',{staticClass:"cell-item",staticStyle:{"position":"relative","top":"-2px","margin-left":"10px"}},[_vm._v(" "+_vm._s(option.title)+" ")])])],1)}),_vm._l((_vm.visibleOptions.filter(
          function (o) { return o.type === 'General Emails (DJ and Producer)'; }
        )),function(option){return _c('div',{key:option.title,staticClass:"col-12 px-0"},[_c('b-form-checkbox',{attrs:{"value":option.topic,"disabled":!!option.disabled},on:{"input":_vm.selectOptions},model:{value:(_vm.topics),callback:function ($$v) {_vm.topics=$$v},expression:"topics"}},[_c('span',{staticClass:"cell-item",staticStyle:{"position":"relative","top":"-2px","margin-left":"10px"}},[_vm._v(" "+_vm._s(option.title)+" ")])])],1)}),(!_vm.isPromoter)?[_c('h4',{staticClass:"text-uppercase"},[_vm._v(" DJ specific emails ")]),_vm._l((_vm.visibleOptions.filter(
            function (o) { return o.type === 'DJ specific emails'; }
          )),function(option){return _c('div',{key:option.title,staticClass:"col-12 px-0"},[_c('b-form-checkbox',{attrs:{"value":option.topic,"disabled":!!option.disabled},on:{"input":_vm.selectOptions},model:{value:(_vm.topics),callback:function ($$v) {_vm.topics=$$v},expression:"topics"}},[_c('span',{staticClass:"cell-item",staticStyle:{"position":"relative","top":"-2px","margin-left":"10px"}},[_vm._v(" "+_vm._s(option.title)+" ")])])],1)}),_vm._l((_vm.visibleOptions.filter(
            function (o) { return o.type === 'Monthly DJ Social Share Assets'; }
          )),function(option){return _c('div',{key:option.title,staticClass:"col-12 px-0"},[_c('b-form-checkbox',{attrs:{"value":option.topic,"disabled":!!option.disabled},on:{"input":_vm.selectOptions},model:{value:(_vm.topics),callback:function ($$v) {_vm.topics=$$v},expression:"topics"}},[_c('span',{staticClass:"cell-item",staticStyle:{"position":"relative","top":"-2px","margin-left":"10px"}},[_vm._v(" "+_vm._s(option.title)+" ")])])],1)}),_c('h4',{staticClass:"text-uppercase"},[_vm._v(" Producer specific emails ")]),_vm._l((_vm.visibleOptions.filter(
            function (o) { return o.type === 'Producer specific emails'; }
          )),function(option){return _c('div',{key:option.title,staticClass:"col-12 px-0"},[_c('b-form-checkbox',{attrs:{"value":option.topic,"disabled":!!option.disabled},on:{"input":_vm.selectOptions},model:{value:(_vm.topics),callback:function ($$v) {_vm.topics=$$v},expression:"topics"}},[_c('span',{staticClass:"cell-item",staticStyle:{"position":"relative","top":"-2px","margin-left":"10px"}},[_vm._v(" "+_vm._s(option.title)+" ")])])],1)})]:[_c('h4',{staticClass:"text-uppercase"},[_vm._v(" Promoter specific emails ")]),_vm._l((_vm.visibleOptions.filter(
            function (o) { return o.type === 'Promoter specific emails'; }
          )),function(option){return _c('div',{key:option.title,staticClass:"col-12 px-0"},[_c('b-form-checkbox',{attrs:{"value":option.topic,"disabled":!!option.disabled},on:{"input":_vm.selectOptions},model:{value:(_vm.topics),callback:function ($$v) {_vm.topics=$$v},expression:"topics"}},[_c('span',{staticClass:"cell-item",staticStyle:{"position":"relative","top":"-2px","margin-left":"10px"}},[_vm._v(" "+_vm._s(option.title)+" ")])]),(!!option.items)?_c('div',{staticClass:"ml-5",class:{
              'email-contact-selections__items--disabled': !_vm.topics.includes(
                option.topic
              )
            }},_vm._l((option.items),function(item,index){return _c('b-form-checkbox',{key:item.interval,attrs:{"checked":_vm.topics.includes(option.topic) && _vm.schedule[option.topic]
                  ? _vm.schedule[option.topic] === item.interval
                  : index === 0,"disabled":!_vm.topics.includes(option.topic) ||
                  _vm.schedule[option.topic] === item.interval},on:{"input":function($event){return _vm.selectSchedule($event, option.topic, item.interval)}}},[_c('span',{staticStyle:{"position":"relative","top":"-2px","margin-left":"10px"}},[_vm._v(" "+_vm._s(item.title)+" ")])])}),1):_vm._e()],1)})]],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }